function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { ReactNode } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "zetz60",
  styles: "border-radius:0.125rem;--tw-bg-opacity:1;background-color:rgb(17 24 39 / var(--tw-bg-opacity));padding-left:0.25rem;padding-right:0.25rem;padding-top:1px;padding-bottom:1px;font-size:8px;font-weight:500;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));@media (min-width: 768px){font-size:10px;}"
} : {
  name: "1248csu-ViewButton",
  styles: "border-radius:0.125rem;--tw-bg-opacity:1;background-color:rgb(17 24 39 / var(--tw-bg-opacity));padding-left:0.25rem;padding-right:0.25rem;padding-top:1px;padding-bottom:1px;font-size:8px;font-weight:500;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));@media (min-width: 768px){font-size:10px;};label:ViewButton;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const ViewButton = ({
  views
}: {
  views: string | ReactNode;
}): JSX.Element => {
  return _jsx("div", {
    css: _ref,
    suppressHydrationWarning: true,
    children: views ? views : ''
  });
};